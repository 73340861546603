

































































































































import Vue from 'vue';
import { IDataSourcePaging } from '@glittr/frontend-core/src/core/v2/data/data-source';
import WorkItemsViewModel from './workItems.vue.model';
import GetPagedWorkItemListRequestModel from '../../../../services/v2/model/get-paged-work-item-list-request-model';

export default Vue.extend({
  auth: true,
  data: () => ({
    vm: new WorkItemsViewModel(),
    request: new GetPagedWorkItemListRequestModel(),
    filter: {
      searchCriteria: undefined as string | undefined,
      sprintIds: [] as string[],
      parentWorkItemIds: [] as number[],
      types: [] as number[],
      statuses: [] as number[],
    },
    projectId: undefined as string | undefined,
    innerPagination: {} as IDataSourcePaging,
  }),
  computed: {
    isAdmin(): boolean {
      let isAdmin = false;
      if (this.$auth.isLoggedIn) {
        isAdmin = this.$auth.isInRole('Admin', 'HostAdmin');
      }
      return isAdmin;
    },
    titleText() {
      if (this.vm.projectDataSource.data?.data) {
        return `${this.vm.projectDataSource.data?.data.projectName}: ${this.$t('page.app.workItemsList.title')}`;
      }
      return this.$t('page.app.workItemsList.title');
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.updateDataSourceFilter();
        this.writeRequestToQuery();
        this.writeFilterToLocalStorage();
      },
    },
    'vm.workItemsDataSource.paging': {
      handler() {
        this.innerPagination = this.vm.workItemsDataSource.paging;
      },
    },
  },
  async mounted() {
    this.projectId = this.$route.params.project;
    // Init dataSource filters for multiselects
    this.vm.workItemStatusDataSource.filter.projectId = this.projectId;
    this.vm.workItemParentDataSource.filter.projectId = this.projectId;
    this.vm.workItemTypeDataSource.filter.projectId = this.projectId;
    this.vm.projectIterationDataSource.filter.projectId = this.projectId;
    this.vm.projectDataSource.filter.projectId = this.projectId;
    // Query data for multiselects
    await this.vm.initialize();
    // Now all data for multiselects is available and the filter can be initialized
    this.initializeFilter();
  },
  methods: {
    initializeFilter() {
      const queryParams = this.$routerUtils.getQueryParams();
      if (!queryParams || Object.keys(queryParams).length === 0) {
        const localStorageFilter = this.$localStorage.get<{projectId: string, filter: Record<string, any>}>('workItemFilter');
        const projId = localStorageFilter?.projectId;
        const filter = localStorageFilter?.filter;
        if (projId === this.projectId && filter) {
          this.$routerUtils.updateQueryParams(filter, { preserve: false });
        }
      }
      this.getRequestFromQuery();
      this.vm.workItemsDataSource.filter.projectId = this.projectId;
      this.updateDataSourceFilter();
    },
    writeRequestToQuery() {
      this.$routerUtils.updateQueryParams(this.vm.transformFilterToQuery(), { preserve: false });
    },
    getRequestFromQuery() {
      const queryParams = this.$routerUtils.getQueryParams();
      const search = queryParams.searchCriteria as string;
      const sprintQuery = queryParams.iterations as string;
      const sprintIds = sprintQuery?.split(',');
      const parentQuery = queryParams.parentsIds as string;
      const parentIds = parentQuery?.split(',').map((id) => Number.parseInt(id, 10));
      const typeQuery = queryParams.types as string;
      const types = typeQuery?.split(',').map((id) => Number.parseInt(id, 10));
      const statusesQuery = queryParams.states as string;
      const statuses = statusesQuery?.split(',').map((id) => Number.parseInt(id, 10));
      this.filter.searchCriteria = search;
      this.filter.sprintIds = sprintIds;
      this.filter.parentWorkItemIds = parentIds;
      this.filter.types = types;
      this.filter.statuses = statuses;
    },
    updateDataSourceFilter() {
      this.vm.workItemsDataSource.filter.searchCriteria = this.filter.searchCriteria;
      this.vm.workItemsDataSource.filter.sprintIds = this.filter.sprintIds;
      this.vm.workItemsDataSource.filter.parentWorkItemIds = this.filter.parentWorkItemIds;
      this.vm.workItemsDataSource.filter.types = this.filter.types;
      this.vm.workItemsDataSource.filter.statuses = this.filter.statuses;
    },
    openRelease() {
      const releaseUrl = window.location.href;
      this.$router.push({ path: `/projects/${this.projectId}/release`, query: { releaseUrl } });
    },
    openCreatePage() {
      this.$router.push(`/projects/${this.projectId}/workItems/new`);
    },
    onPaginationInput() {
      this.$debounce(() => {
        this.vm.workItemsDataSource.paging = this.innerPagination;
      }, 500, this)();
    },
    writeFilterToLocalStorage() {
      const filterObject = {
        projectId: this.projectId,
        filter: this.vm.transformFilterToQuery(),
      };
      this.$localStorage.set('workItemFilter', filterObject);
    },
  },
});
