// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetPagedWorkItemListRequestModel from '../../model/get-paged-work-item-list-request-model';
import GetPagedWorkItemListRequestDTO from '../dto/get-paged-work-item-list-request-dto';
import dtoSchema from '../schemas/get-paged-work-item-list-request-schema';

export default abstract class GetPagedWorkItemListRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetPagedWorkItemListRequestDTO>) {
    super();
    this.paging = {};
    if (data) {
      this.paging = {
        currentPage: data['paging.CurrentPage'],
        pageSize: data['paging.PageSize'],
        includeCount: data['paging.IncludeCount'],
        noPaging: data['paging.NoPaging'],
      };
      this.projectId = data.projectId;
      this.sprintIds = data.sprintIds;
      this.parentWorkItemIds = data.parentWorkItemIds;
      this.types = data.types;
      this.statuses = data.statuses;
      this.searchCriteria = data.searchCriteria;
    }
  }

  paging: {
    /**
    * @type {int32}
    */
    currentPage?: number;
    /**
    * @type {int32}
    */
    pageSize?: number;
    /**
    */
    includeCount?: boolean;
    /**
    */
    noPaging?: boolean;
  };

  /**
  * @type {uuid}
  */
  projectId?: string;
  /**
  */
  sprintIds?: string[];
  /**
  */
  parentWorkItemIds?: number[];
  /**
  */
  types?: number[];
  /**
  */
  statuses?: number[];
  /**
  */
  searchCriteria?: string;

  static toModel(dto: DeepPartial<GetPagedWorkItemListRequestDTO>): GetPagedWorkItemListRequestModel;
  static toModel(dto: DeepPartial<GetPagedWorkItemListRequestDTO> | undefined | null): GetPagedWorkItemListRequestModel | undefined;
  static toModel(dto: DeepPartial<GetPagedWorkItemListRequestDTO> | undefined | null): GetPagedWorkItemListRequestModel | undefined {
    return dto ? new GetPagedWorkItemListRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetPagedWorkItemListRequestModel> | GetPagedWorkItemListRequestModel): GetPagedWorkItemListRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      'paging.CurrentPage': unwrapped.paging?.currentPage,
      'paging.PageSize': unwrapped.paging?.pageSize,
      'paging.IncludeCount': unwrapped.paging?.includeCount,
      'paging.NoPaging': unwrapped.paging?.noPaging,
      projectId: unwrapped.projectId,
      sprintIds: unwrapped.sprintIds,
      parentWorkItemIds: unwrapped.parentWorkItemIds,
      types: unwrapped.types,
      statuses: unwrapped.statuses,
      searchCriteria: unwrapped.searchCriteria,
    } as GetPagedWorkItemListRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetPagedWorkItemListRequestModel, can not map to GetPagedWorkItemListRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
