// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import WorkItemListPagingResultModel from '../../model/work-item-list-paging-result-model';
import WorkItemListPagingResultDTO from '../dto/work-item-list-paging-result-dto';
import dtoSchema from '../schemas/work-item-list-paging-result-schema';
import WorkItemListModel from '../../model/work-item-list-model';
import ResourceLinkModel from '../../model/resource-link-model';
import PaginationDataModel from '../../model/pagination-data-model';

export default abstract class WorkItemListPagingResultModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<WorkItemListPagingResultDTO>) {
    super();
    if (data) {
      this.items = data.items?.map((i) => WorkItemListModel.toModel(i)) ?? [];
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.paginationData = data.paginationData ? PaginationDataModel.toModel(data.paginationData) : undefined;
    }
  }
  /**
  */
  items?: WorkItemListModel[];
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  paginationData?: PaginationDataModel;

  static toModel(dto: DeepPartial<WorkItemListPagingResultDTO>): WorkItemListPagingResultModel;
  static toModel(dto: DeepPartial<WorkItemListPagingResultDTO> | undefined | null): WorkItemListPagingResultModel | undefined;
  static toModel(dto: DeepPartial<WorkItemListPagingResultDTO> | undefined | null): WorkItemListPagingResultModel | undefined {
    return dto ? new WorkItemListPagingResultModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<WorkItemListPagingResultModel> | WorkItemListPagingResultModel): WorkItemListPagingResultDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      items: unwrapped.items,
      links: unwrapped.links,
      paginationData: unwrapped.paginationData,
    } as WorkItemListPagingResultDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for WorkItemListPagingResultModel, can not map to WorkItemListPagingResultDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
