// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import SubmitTestResultRequestModel from '../../model/submit-test-result-request-model';
import SubmitTestResultRequestDTO from '../dto/submit-test-result-request-dto';
import dtoSchema from '../schemas/submit-test-result-request-schema';

export default abstract class SubmitTestResultRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<SubmitTestResultRequestDTO>) {
    super();
    if (data) {
      this.result = data.result;
      this.bugSummary = data.bugSummary ?? undefined;
      this.description = data.description ?? undefined;
      this.projectId = data.projectId;
      this.workItemId = data.workItemId;
    }
  }
  /**
  * @type {int32}
  */
  result?: 0 | 1; // TestResultDTO
  /**
  */
  bugSummary?: string;
  /**
  */
  description?: string;
  /**
  * @type {uuid}
  */
  projectId?: string;
  /**
  * @type {int32}
  */
  workItemId?: number;

  static toModel(dto: DeepPartial<SubmitTestResultRequestDTO>): SubmitTestResultRequestModel;
  static toModel(dto: DeepPartial<SubmitTestResultRequestDTO> | undefined | null): SubmitTestResultRequestModel | undefined;
  static toModel(dto: DeepPartial<SubmitTestResultRequestDTO> | undefined | null): SubmitTestResultRequestModel | undefined {
    return dto ? new SubmitTestResultRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<SubmitTestResultRequestModel> | SubmitTestResultRequestModel): SubmitTestResultRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      result: unwrapped.result,
      bugSummary: unwrapped.bugSummary,
      description: unwrapped.description,
      projectId: unwrapped.projectId,
      workItemId: unwrapped.workItemId,
    } as SubmitTestResultRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for SubmitTestResultRequestModel, can not map to SubmitTestResultRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
