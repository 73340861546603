import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import SubmitAcceptanceRequestModelBase from '../_generated/modelBases/submit-acceptance-request-model-base';

export default class SubmitAcceptanceRequestModel extends SubmitAcceptanceRequestModelBase {
  // For instructions on how to use Models visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/429/ServiceModels-DTOs
  schema = {
    ...queryBuilder.properties({
      description: { required: true, minLength: 1 },
    }),
  };
}
