import Vue from 'vue';
import ViewModelBase from '@glittr/frontend-core/src/core/v2/app/viewModelBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import WorkItemModel from '../../../services/v2/model/work-item-model';
import SubmitAcceptanceRequestModel from '../../../services/v2/model/submit-acceptance-request-model';

export default class AcceptanceViewModel extends ViewModelBase {
  model: SubmitAcceptanceRequestModel = new SubmitAcceptanceRequestModel();
  error: any | undefined = '';
  isAcceptanceLoading: boolean | undefined = false;

  workItemDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.workItems.getWorkItemById,
  });

  // eslint-disable-next-line no-empty-function
  async initialize(): Promise<void> {
  }

  get workItem(): WorkItemModel {
    return this.workItemDataSource.data?.data || new WorkItemModel();
  }

  async submit() {
    this.isAcceptanceLoading = true;
    try {
      if (this.model.validate()) {
        const response = await Vue.$service.v2.api.workItems.submitAcceptance(this.model);
        if (response) {
          Vue.$router.push(`/projects/${this.workItemDataSource.filter.projectId}/workItems`);
        }
      }
      this.isAcceptanceLoading = false;
    } catch (ex) {
      this.error = ex;
      this.isAcceptanceLoading = false;
    }
  }
}
