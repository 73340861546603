// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetPagedWorkItemListRequestModel from '../../model/get-paged-work-item-list-request-model';
import CreateWorkItemRequestModel from '../../model/create-work-item-request-model';
import GetWorkItemByIdRequestModel from '../../model/get-work-item-by-id-request-model';
import GetCommentsByWorkItemIdRequestModel from '../../model/get-comments-by-work-item-id-request-model';
import GetParentElementsRequestModel from '../../model/get-parent-elements-request-model';
import GetWorkItemStatusesRequestModel from '../../model/get-work-item-statuses-request-model';
import GetWorkItemTypesRequestModel from '../../model/get-work-item-types-request-model';
import SubmitAcceptanceRequestModel from '../../model/submit-acceptance-request-model';
import SubmitTestResultRequestModel from '../../model/submit-test-result-request-model';
import WorkItemListPagingResultModel from '../../model/work-item-list-paging-result-model';
import WorkItemModel from '../../model/work-item-model';
import WorkItemCommentListResultModel from '../../model/work-item-comment-list-result-model';
import Int64LookupListResultModel from '../../model/int64-lookup-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getPagedWorkItemList(request: GetPagedWorkItemListRequestModel, config?: RequestConfig) {
    const requestDTO = GetPagedWorkItemListRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-paged-work-item-list'] ?? 'workItems/{projectId}';
    endpointPath = endpointPath.replace(/\{projectId\}/g, `${requestDTO.projectId}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        sprintIds: requestDTO.sprintIds,
        parentWorkItemIds: requestDTO.parentWorkItemIds,
        types: requestDTO.types,
        statuses: requestDTO.statuses,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(WorkItemListPagingResultModel.toModel(dto));
  },
  /**  */
  async createWorkItem(request: CreateWorkItemRequestModel, config?: RequestConfig) {
    const requestDTO = CreateWorkItemRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-work-item'] ?? 'workItems/{projectId}';
    endpointPath = endpointPath.replace(/\{projectId\}/g, `${requestDTO.projectId}`);
    const response = await service.post<any>(endpointPath, {
      body: {
        title: requestDTO.title,
        description: requestDTO.description,
        workItemType: requestDTO.workItemType,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async getWorkItemById(request: GetWorkItemByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetWorkItemByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-work-item-by-id'] ?? 'workItems/{projectId}/{workItemId}';
    endpointPath = endpointPath.replace(/\{projectId\}/g, `${requestDTO.projectId}`);
    endpointPath = endpointPath.replace(/\{workItemId\}/g, `${requestDTO.workItemId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(WorkItemModel.toModel(dto));
  },
  /**  */
  async getCommentsByWorkItemId(request: GetCommentsByWorkItemIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetCommentsByWorkItemIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-comments-by-work-item-id'] ?? 'workItems/{projectId}/{workItemId}/comments';
    endpointPath = endpointPath.replace(/\{projectId\}/g, `${requestDTO.projectId}`);
    endpointPath = endpointPath.replace(/\{workItemId\}/g, `${requestDTO.workItemId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(WorkItemCommentListResultModel.toModel(dto));
  },
  /**  */
  async getParentElements(request: GetParentElementsRequestModel, config?: RequestConfig) {
    const requestDTO = GetParentElementsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-parent-elements'] ?? 'workItems/{projectId}/parentElements';
    endpointPath = endpointPath.replace(/\{projectId\}/g, `${requestDTO.projectId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int64LookupListResultModel.toModel(dto));
  },
  /**  */
  async getWorkItemStatuses(request: GetWorkItemStatusesRequestModel, config?: RequestConfig) {
    const requestDTO = GetWorkItemStatusesRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-work-item-statuses'] ?? 'workItems/{projectId}/statuses';
    endpointPath = endpointPath.replace(/\{projectId\}/g, `${requestDTO.projectId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int64LookupListResultModel.toModel(dto));
  },
  /**  */
  async getWorkItemTypes(request: GetWorkItemTypesRequestModel, config?: RequestConfig) {
    const requestDTO = GetWorkItemTypesRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-work-item-types'] ?? 'workItems/{projectId}/types';
    endpointPath = endpointPath.replace(/\{projectId\}/g, `${requestDTO.projectId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int64LookupListResultModel.toModel(dto));
  },
  /**  */
  async submitAcceptance(request: SubmitAcceptanceRequestModel, config?: RequestConfig) {
    const requestDTO = SubmitAcceptanceRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-submit-acceptance'] ?? 'workItems/{projectId}/{workItemId}/submitAcceptance';
    endpointPath = endpointPath.replace(/\{projectId\}/g, `${requestDTO.projectId}`);
    endpointPath = endpointPath.replace(/\{workItemId\}/g, `${requestDTO.workItemId}`);
    const response = await service.post<any>(endpointPath, {
      body: {
        description: requestDTO.description,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async submitTestResult(request: SubmitTestResultRequestModel, config?: RequestConfig) {
    const requestDTO = SubmitTestResultRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-submit-test-result'] ?? 'workItems/{projectId}/{workItemId}/submitTestResult';
    endpointPath = endpointPath.replace(/\{projectId\}/g, `${requestDTO.projectId}`);
    endpointPath = endpointPath.replace(/\{workItemId\}/g, `${requestDTO.workItemId}`);
    const response = await service.post<any>(endpointPath, {
      body: {
        result: requestDTO.result,
        bugSummary: requestDTO.bugSummary,
        description: requestDTO.description,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
});
