

































import Vue from 'vue';

export default Vue.extend({
  auth: true,
  computed: {
    isAdmin(): boolean {
      let isAdmin = false;
      if (this.$auth.isLoggedIn) {
        isAdmin = this.$auth.isInRole('Admin', 'HostAdmin');
      }
      return isAdmin;
    },
  },
});
