





























































































































































import Vue from 'vue';
import WorkItemDetailViewModel from './workitem-detail.vue.model';

export default Vue.extend({
  data: () => ({
    vm: new WorkItemDetailViewModel(),
    projectId: undefined as string | undefined,
  }),
  computed: {
    pageTitle() {
      const type = this.vm.workItem.workItemType === undefined ? undefined : this.$tEnum('page.app.workItems.types', this.vm.workItem.workItemType);
      return `${type} #${this.vm.workItem.id} - ${this.vm.workItem.title}`;
    },
    buttonInfoText() {
      if (this.vm.workItem.workItemState === 4) {
        return this.$t('page.app.workItems.buttonInformation.closed');
      }
      if (this.vm.workItem.workItemState !== 3) {
        return this.$t('page.app.workItems.buttonInformation.inProgress');
      }
      return '';
    },
  },
  async mounted() {
    this.vm.initialize();
    const workItemId = Number.parseInt(this.$route.params.id, 10);
    this.projectId = this.$route.params.projectId;
    this.vm.workItemDetailDataSource.filter.workItemId = workItemId;
    this.vm.workItemDetailDataSource.filter.projectId = this.projectId;
    this.vm.workItemCommentDataSource.filter.workItemId = workItemId;
    this.vm.workItemCommentDataSource.filter.projectId = this.projectId;
    await this.vm.workItemDetailDataSource.select();
    await this.vm.workItemCommentDataSource.select();
  },
  methods: {
    back() {
      this.$router.back();
    },
  },
});
