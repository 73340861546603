import coreRoutes from '@glittr/frontend-core/src/routes';
import Home from './views/pages/index.vue';

export default {
  mode: 'history',
  base: process.env!.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    ...coreRoutes,
    {
      path: '/projects',
      name: 'projects',
      component: () => import('./views/pages/app/admin/overview-projects.vue'),
    },
    {
      path: '/projects/:project',
      redirect: { name: 'workItems' },
    },
    {
      path: '/projects/:projectId/testresult/:id',
      name: 'testresult',
      component: () => import('./views/pages/app/testresult.vue'),
    },
    {
      path: '/projects/:projectId/acceptance/:id',
      name: 'testresult',
      component: () => import('./views/pages/app/acceptance.vue'),
    },
    {
      path: '/projects/:project/workItems',
      name: 'workItems',
      component: () => import('./views/pages/app/workItem/workItems.vue'),
    },
    {
      path: '/projects/:project/workItems/new',
      name: 'newWorkItem',
      component: () => import('./views/pages/app/workItem/reportError/reportError.vue'),
    },
    {
      path: '/projects/:projectId/workitems/:id',
      name: 'workitemDetail',
      component: () => import('./views/pages/app/workitem-detail.vue'),
    },
    {
      path: '/projects/:project/release',
      name: 'release',
      component: () => import('./views/pages/app/workItem/ReleasePage/workItemsRelease.vue'),
    },
    {
      path: '/:tenant/:workspace',
      name: 'dashboard',
      component: () => import('./views/pages/app/dashboard.vue'),
    },
  ],
};
