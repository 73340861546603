import Vue from 'vue';
import ViewModelBase from '@glittr/frontend-core/src/core/v2/app/viewModelBase';
import CreateWorkItemRequestModel from '../../../../../services/v2/model/create-work-item-request-model';

export default class ReportErrorViewModel extends ViewModelBase {
  model: CreateWorkItemRequestModel = new CreateWorkItemRequestModel();
  error: any | undefined = '';
  isCreationLoading: boolean | undefined = false;
  projectId: string | undefined;

  initialize(): Promise<void> {
    throw new Error('Method not implemented.');
  }

  async submit() {
    this.isCreationLoading = true;
    try {
      this.model.projectId = this.projectId;
      this.model.workItemType = 3; // 3 = Bug
      if (this.model.validate()) {
        const response = await Vue.$service.v2.api.workItems.createWorkItem(this.model);
        if (response && response.data) {
          Vue.$router.replace(`/projects/${this.projectId}/workItems/${response.data}`);
        }
      }
      this.isCreationLoading = false;
    } catch (ex) {
      this.error = ex;
      this.isCreationLoading = false;
    }
  }
}
