













import mixins from '@glittr/frontend-core/src/mixins';
import validatable from '@glittr/frontend-core/src/mixins/validatable/validatable';
import { VueEditor } from 'vue2-editor';

export default mixins(validatable).extend({
  name: 'IlTextareaEditor',
  components: { VueEditor },
  props: {
    value: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: undefined },
    required: { type: Boolean, default: false },
  },
  data: () => ({
    innerValue: undefined as string | undefined,
  }),
  computed: {},
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.innerValue = value;
      },
    },
  },
  mounted() {},
  methods: {
    onInput(value: string) {
      this.$emit('input', value);
    },
  },
});
