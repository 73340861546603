import Vue from 'vue';
import ViewModelBase from '@glittr/frontend-core/src/core/v2/app/viewModelBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import WorkItemModel from '../../../services/v2/model/work-item-model';
import SubmitTestResultRequestModel from '../../../services/v2/model/submit-test-result-request-model';

export default class TestResultViewModel extends ViewModelBase {
  model: SubmitTestResultRequestModel = new SubmitTestResultRequestModel();
  error: any | undefined = '';

  workItemDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.workItems.getWorkItemById,
  });

  // eslint-disable-next-line no-empty-function
  async initialize(): Promise<void> {
  }

  get workItem(): WorkItemModel {
    return this.workItemDataSource.data?.data || new WorkItemModel();
  }

  get bugSummaryRequired(): boolean {
    return this.workItem.workItemType === 2 /* story */ && this.model.result === 1; /* failed */
  }

  async submit() {
    try {
      this.model.schema = {
        ...queryBuilder.properties({
          result: { required: true },
          bugSummary: { required: this.bugSummaryRequired, minLength: 1 },
          description: { required: true, minLength: 1 },
        }),
      };
      if (this.model.validate()) {
        const response = await Vue.$service.v2.api.workItems.submitTestResult(this.model);
        if (response) {
          Vue.$router.push(`/projects/${this.workItemDataSource.filter.projectId}/workitems/${this.workItemDataSource.filter.workItemId}`);
        }
      }
    } catch (ex) {
      this.error = ex;
    }
  }
}
