












































import Vue from 'vue';
import IlTextareaEditor from '../../../../../components/il-textarea-editor.vue';
import ReportErrorViewModel from './reportError.vue.model';

export default Vue.extend({
  components: {
    IlTextareaEditor,
  },
  data: () => ({
    vm: new ReportErrorViewModel(),
  }),
  beforeMount() {
    this.vm.projectId = this.$route.params.project;
  },
  methods: {
    back() {
      this.$router.back();
    },
  },
});
