// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import WorkItemListModel from '../../model/work-item-list-model';
import WorkItemListDTO from '../dto/work-item-list-dto';
import dtoSchema from '../schemas/work-item-list-schema';

export default abstract class WorkItemListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<WorkItemListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.title = data.title ?? undefined;
      this.type = data.type;
      this.status = data.status;
      this.sprintId = data.sprintId;
      this.sprintName = data.sprintName ?? undefined;
      this.parentId = data.parentId ?? undefined;
      this.parentTitle = data.parentTitle ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  title?: string;
  /**
  * @type {int32}
  */
  type?: 0 | 1 | 2 | 3 | 4; // WorkItemTypeDTO
  /**
  * @type {int32}
  */
  status?: 0 | 1 | 2 | 3 | 4; // WorkItemStatusDTO
  /**
  * @type {uuid}
  */
  sprintId?: string;
  /**
  */
  sprintName?: string;
  /**
  * @type {int64}
  */
  parentId?: number;
  /**
  */
  parentTitle?: string;

  static toModel(dto: DeepPartial<WorkItemListDTO>): WorkItemListModel;
  static toModel(dto: DeepPartial<WorkItemListDTO> | undefined | null): WorkItemListModel | undefined;
  static toModel(dto: DeepPartial<WorkItemListDTO> | undefined | null): WorkItemListModel | undefined {
    return dto ? new WorkItemListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<WorkItemListModel> | WorkItemListModel): WorkItemListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      title: unwrapped.title,
      type: unwrapped.type,
      status: unwrapped.status,
      sprintId: unwrapped.sprintId,
      sprintName: unwrapped.sprintName,
      parentId: unwrapped.parentId,
      parentTitle: unwrapped.parentTitle,
    } as WorkItemListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for WorkItemListModel, can not map to WorkItemListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
