import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import CreateWorkItemRequestModelBase from '../_generated/modelBases/create-work-item-request-model-base';

export default class CreateWorkItemRequestModel extends CreateWorkItemRequestModelBase {
  // For instructions on how to use Models visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/429/ServiceModels-DTOs
  schema = {
    ...queryBuilder.properties({
      title: { required: true, minLength: 1 },
      description: { required: true, minLength: 1 },
    }),
  };
}
