




























import Vue from 'vue';

export default Vue.extend({
  auth: true,
  role: ['admin', 'hostadmin'],
  data: () => ({
    releaseUrl: undefined as string | undefined,
    error: undefined as string | undefined,
  }),
  beforeMount() {
    const releaseUrlFromQuery = this.$route.query.releaseUrl as string;
    if (!releaseUrlFromQuery) {
      this.error = 'ReleaseUrl in query is missing';
    } else {
      this.releaseUrl = this.$route.query.releaseUrl as string;
    }
  },
});
