import ViewModelBase from '@glittr/frontend-core/src/core/v2/app/viewModelBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';

export default class WorkItemsViewModel extends ViewModelBase {
  async initialize(): Promise<void> {
    await Promise.allSettled([
      this.workItemStatusDataSource.select(),
      this.workItemParentDataSource.select(),
      this.workItemTypeDataSource.select(),
      this.projectIterationDataSource.select(),
      this.projectDataSource.select(),
    ]);
  }

  workItemsDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.workItems.getPagedWorkItemList,
  });

  workItemStatusDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.workItems.getWorkItemStatuses,
  });

  workItemParentDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.workItems.getParentElements,
  });

  workItemTypeDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.workItems.getWorkItemTypes,
  });

  projectIterationDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.azureProjects.getProjectIterationLookup,
  });

  projectDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.azureProjects.getProjectById,
  });

  get iterations() {
    return this.projectIterationDataSource.data?.items.map((record) => record.data);
  }

  get workItemTypes() {
    return this.workItemTypeDataSource.data?.items.map((record) => record.data);
  }

  get workItemParents() {
    return this.workItemParentDataSource.data?.items.map((record) => record.data);
  }

  get workItemStatuses() {
    return this.workItemStatusDataSource.data?.items.map((record) => record.data);
  }

  transformFilterToQuery() {
    const queries = {
      searchCriteria: this.workItemsDataSource.filter.searchCriteria ?? undefined,
      iterations: this.workItemsDataSource.filter.sprintIds && this.workItemsDataSource.filter.sprintIds.length > 0 ? this.workItemsDataSource.filter.sprintIds : undefined,
      parentsIds: this.workItemsDataSource.filter.parentWorkItemIds && this.workItemsDataSource.filter.parentWorkItemIds.length > 0 ? this.workItemsDataSource.filter.parentWorkItemIds.map((item) => item.toString()) : undefined,
      types: this.workItemsDataSource.filter.types && this.workItemsDataSource.filter.types.length > 0 ? this.workItemsDataSource.filter.types.map((item) => item.toString()) : undefined,
      states: this.workItemsDataSource.filter.statuses && this.workItemsDataSource.filter.statuses.length > 0 ? this.workItemsDataSource.filter.statuses.map((item) => item.toString()) : undefined,
    };
    return queries;
  }
}
