












































































import Vue from 'vue';
import { IDataSourcePaging } from '@glittr/frontend-core/src/core/v2/data/data-source';
import AzureProjectsOverviewViewModel from './overview-projects.vue.model';

export default Vue.extend({
  auth: true,
  role: ['admin', 'hostadmin'],
  data: () => ({
    vm: new AzureProjectsOverviewViewModel(),
    innerPagination: {} as IDataSourcePaging,
  }),
  watch: {
    'vm.azureProjectsDataSource.paging': {
      handler() {
        this.innerPagination = this.vm.azureProjectsDataSource.paging;
      },
    },
  },
  mounted() {
    this.vm.initialize();
  },
  methods: {
    openInDevOps(projectId: string | undefined) {
      window.open(Vue.$config.values['default-azureDevOps-url'] + projectId);
    },
    onPaginationInput() {
      this.$debounce(() => {
        this.vm.azureProjectsDataSource.paging = this.innerPagination;
      }, 500, this)();
    },
  },
});
