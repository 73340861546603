




































































import Vue from 'vue';
import TestResultViewModel from './testresult.vue.model';
import IlTextareaEditor from '../../../components/il-textarea-editor.vue';

export default Vue.extend({
  components: {
    IlTextareaEditor,
  },
  data: () => ({
    vm: new TestResultViewModel(),
  }),
  computed: {
    pageTitle() {
      const type = this.vm.workItem.workItemType === undefined ? undefined : this.$tEnum('page.app.workItems.types', this.vm.workItem.workItemType);
      return `${this.$t('page.app.testResult.title')} - ${type} #${this.vm.workItem.id}`;
    },
    workItemName() {
      const type = this.vm.workItem.workItemType === undefined ? undefined : this.$tEnum('page.app.workItems.types', this.vm.workItem.workItemType);
      return `${type} #${this.vm.workItem.id} - ${this.vm.workItem.title}`;
    },
    infoText() {
      switch (this.vm.workItem.workItemType) {
      case 2: // story
        switch (this.vm.model.result) {
        case 0: // success
          return this.$t('page.app.testResult.infoSuccessful');
        case 1: // failed
          return this.$t('page.app.testResult.storyCreateNewBug');
        default:
          return undefined;
        }
      case 3: // bug
        switch (this.vm.model.result) {
        case 0: // success
          return this.$t('page.app.testResult.infoSuccessful');
        case 1: // failed
          return this.$t('page.app.testResult.bugStatusActive');
        default:
          return undefined;
        }
      default:
        return undefined;
      }
    },
  },
  async mounted() {
    this.vm.initialize();
    const workItemId = Number.parseInt(this.$route.params.id, 10);
    const { projectId } = this.$route.params;
    this.vm.workItemDataSource.filter.workItemId = workItemId;
    this.vm.workItemDataSource.filter.projectId = projectId;
    this.vm.model.workItemId = workItemId;
    this.vm.model.projectId = projectId;
    await this.vm.workItemDataSource.select();
  },
  methods: {
    back() {
      this.$router.back();
    },
  },
});
