// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import WorkItemModel from '../../model/work-item-model';
import WorkItemDTO from '../dto/work-item-dto';
import dtoSchema from '../schemas/work-item-schema';

export default abstract class WorkItemModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<WorkItemDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.title = data.title ?? undefined;
      this.workItemState = data.workItemState;
      this.workItemType = data.workItemType;
      this.assignedPerson = data.assignedPerson ?? undefined;
      this.parentId = data.parentId;
      this.description = data.description ?? undefined;
      this.acceptanceCriteria = data.acceptanceCriteria ?? undefined;
      this.reproSteps = data.reproSteps ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  title?: string;
  /**
  * @type {int32}
  */
  workItemState?: 0 | 1 | 2 | 3 | 4; // WorkItemStateDTO
  /**
  * @type {int32}
  */
  workItemType?: 0 | 1 | 2 | 3 | 4; // WorkItemTypeDTO
  /**
  */
  assignedPerson?: string;
  /**
  * @type {int64}
  */
  parentId?: number;
  /**
  */
  description?: string;
  /**
  */
  acceptanceCriteria?: string;
  /**
  */
  reproSteps?: string;

  static toModel(dto: DeepPartial<WorkItemDTO>): WorkItemModel;
  static toModel(dto: DeepPartial<WorkItemDTO> | undefined | null): WorkItemModel | undefined;
  static toModel(dto: DeepPartial<WorkItemDTO> | undefined | null): WorkItemModel | undefined {
    return dto ? new WorkItemModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<WorkItemModel> | WorkItemModel): WorkItemDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      title: unwrapped.title,
      workItemState: unwrapped.workItemState,
      workItemType: unwrapped.workItemType,
      assignedPerson: unwrapped.assignedPerson,
      parentId: unwrapped.parentId,
      description: unwrapped.description,
      acceptanceCriteria: unwrapped.acceptanceCriteria,
      reproSteps: unwrapped.reproSteps,
    } as WorkItemDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for WorkItemModel, can not map to WorkItemDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
