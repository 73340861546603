import ViewModelBase from '@glittr/frontend-core/src/core/v2/app/viewModelBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';

export default class AzureProjectsOverviewViewModel extends ViewModelBase {
  async initialize(): Promise<void> {
    await this.azureProjectsDataSource.select();
  }

  azureProjectsDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.azureProjects.getPagedAzureProjectsList,
  });
}
