// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CustomerProjectModel from '../../model/customer-project-model';
import CustomerProjectDTO from '../dto/customer-project-dto';
import dtoSchema from '../schemas/customer-project-schema';

export default abstract class CustomerProjectModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CustomerProjectDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.projectName = data.projectName ?? undefined;
    }
  }
  /**
  * @type {uuid}
  */
  id?: string;
  /**
  */
  projectName?: string;

  static toModel(dto: DeepPartial<CustomerProjectDTO>): CustomerProjectModel;
  static toModel(dto: DeepPartial<CustomerProjectDTO> | undefined | null): CustomerProjectModel | undefined;
  static toModel(dto: DeepPartial<CustomerProjectDTO> | undefined | null): CustomerProjectModel | undefined {
    return dto ? new CustomerProjectModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CustomerProjectModel> | CustomerProjectModel): CustomerProjectDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      projectName: unwrapped.projectName,
    } as CustomerProjectDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CustomerProjectModel, can not map to CustomerProjectDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
