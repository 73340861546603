


























































import Vue from 'vue';
import IlTextareaEditor from '../../../components/il-textarea-editor.vue';
import AcceptanceViewModel from './acceptance.vue.model';

export default Vue.extend({
  components: {
    IlTextareaEditor,
  },
  data: () => ({
    vm: new AcceptanceViewModel(),
  }),
  computed: {
    pageTitle() {
      const type = this.vm.workItem.workItemType === undefined ? undefined : this.$tEnum('page.app.workItems.types', this.vm.workItem.workItemType);
      return `${this.$t('page.app.acceptance.title')} - ${type} #${this.vm.workItem.id}`;
    },
    workItemName() {
      const type = this.vm.workItem.workItemType === undefined ? undefined : this.$tEnum('page.app.workItems.types', this.vm.workItem.workItemType);
      return `${type} #${this.vm.workItem.id} - ${this.vm.workItem.title}`;
    },
  },
  async mounted() {
    this.vm.initialize();
    const workItemId = Number.parseInt(this.$route.params.id, 10);
    const { projectId } = this.$route.params;
    this.vm.workItemDataSource.filter.workItemId = workItemId;
    this.vm.workItemDataSource.filter.projectId = projectId;
    this.vm.model.workItemId = workItemId;
    this.vm.model.projectId = projectId;
    await this.vm.workItemDataSource.select();
  },
  methods: {
    back() {
      this.$router.back();
    },
    openDetail() {
      const route = this.$router.resolve(`/projects/${this.vm.model.projectId}/workItems/${this.vm.model.workItemId}`);
      window.open(route.href, '_blank');
    },
  },
});
