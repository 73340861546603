// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetPagedAzureProjectsListRequestModel from '../../model/get-paged-azure-projects-list-request-model';
import GetProjectByIdRequestModel from '../../model/get-project-by-id-request-model';
import GetProjectIterationLookupRequestModel from '../../model/get-project-iteration-lookup-request-model';
import CustomerProjectPagingResultModel from '../../model/customer-project-paging-result-model';
import CustomerProjectModel from '../../model/customer-project-model';
import GuidLookupListResultModel from '../../model/guid-lookup-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getPagedAzureProjectsList(request: GetPagedAzureProjectsListRequestModel, config?: RequestConfig) {
    const requestDTO = GetPagedAzureProjectsListRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-paged-azure-projects-list'] ?? 'azure/projects';
    const response = await service.get<any>(endpointPath, {
      query: {
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CustomerProjectPagingResultModel.toModel(dto));
  },
  /**  */
  async getProjectById(request: GetProjectByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetProjectByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-project-by-id'] ?? 'azure/projects/{projectId}';
    endpointPath = endpointPath.replace(/\{projectId\}/g, `${requestDTO.projectId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CustomerProjectModel.toModel(dto));
  },
  /**  */
  async getProjectIterationLookup(request: GetProjectIterationLookupRequestModel, config?: RequestConfig) {
    const requestDTO = GetProjectIterationLookupRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-project-iteration-lookup'] ?? 'azure/projects/{projectId}/iterationsLookup';
    endpointPath = endpointPath.replace(/\{projectId\}/g, `${requestDTO.projectId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(GuidLookupListResultModel.toModel(dto));
  },
});
