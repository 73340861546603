import Vue from 'vue';
import ViewModelBase from '@glittr/frontend-core/src/core/v2/app/viewModelBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import WorkItemModel from '../../../services/v2/model/work-item-model';

export default class WorkItemDetailViewModel extends ViewModelBase {
  async initialize(): Promise<void> {
    await this.workItemDetailDataSource.select();
  }

  workItemDetailDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.workItems.getWorkItemById,
  });

  workItemCommentDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.workItems.getCommentsByWorkItemId,
  });

  get workItem():WorkItemModel {
    // This mapping is only necessary because the current p-list-view can't handle DataRecords
    return this.workItemDetailDataSource.data?.data || new WorkItemModel();
  }

  get comments() {
    return this.workItemCommentDataSource.data?.items || [];
  }
}
